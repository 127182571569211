import {VueController} from '@/service/support/vuex-controller';
import SettingsItem from '@/components/SettingsItem';
import StripePayment from "@/components/StripePayment";
import orgService from "@/service/org-service";
import baseService from "@/service/support/base-service";
import PlanSelection from "@/components/PlanSelection";
import {EventBus} from '@/service/support/event-bus';
import stripeService from "@/service/stripe-service";
import moment from 'moment';

import * as awsVars from '@/config/aws-exports';
import vaultService from "@/service/vault-service";
let awsmobile = awsVars.default(process.env);

const usdFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2
});

export default VueController("Settings", {
	state: [
		"organization",
		"organizationId",
		"members",
		"cognito",
		"user",
		"newOrganizationTier",
		"stripeDiscount",
		"subscriptionPlanOptions",
		"stripeInterval"
	],
	data() {
		return {
			headers: [
				{
					text: "Name",
					align: 'start',
					sortable: true,
					value: 'displayName'
				},
				{
					text: "Email",
					value: 'email'
				},
				{
					text: "Access Portals",
					value: "access_vault"
				},
				{
					text: "Create Portals",
					value: "create_vault"
				},
				{
					text: "Manage Permissions",
					value: "manage_perms"
				},
				{
					text: "View Files",
					value: "view_files"
				},
				{
					text: "Rename Files",
					value: "rename_files"
				},
				{
					text: "Delete Files",
					value: "delete_files"
				},
				{
					text: "Upload Files",
					value: "upload_files"
				},
				{
					text: "Notify Upload",
					value: "upload_notification"
				},
				{
					text: "Notify Download",
					value: "download_notification"
				},
				{
					text: "Actions",
					value: "actions"
				}
			],
			cancelReasons: [
				{reason : "Price"},
				{reason : "Missing Features"},
				{reason : "Complicated to use"},
				{reason : "Other"}
			],
			addMemberError: "",
			addMemberAlert: false,
			memberSearch: "",
			memberToDelete: "",
			cancelComments: "",
			cancelSelection: "",
			paymentStepper: 1,
			perms: [],
			tabs: 0,
			linkIsEmbedded: false,
			promoCodeFailed: false,
			promoCodeValid: false,
			promoCode: "",
			promoDetails: "",
			updateToTier: "",
			stripeSubId: "",
			stripeStatus: "",
			stripeNextPayment: "",
			stripeStartDate: "",
			stripeLastFour: "",
			stripeBillingAmount: "",
			stripeNextInvoiceAmount: "",
			stripeTrialEndDate: "",
			stripeCardBrand: "",
			stripeCardExpiration: "",
			updateStripeCard: false,
			loading: true,
			invoices: [],
			tabIndex: [0, 0],
			labelIcon: {
				dataOn: '\u2713',
				dataOff: '\u2715'
			},
			modals: {
				navigateToStripe: false,
				switchPeriod: false,
				organizationProfile: false,
				passwordRequirements: false,
				deviceApprovals: false,
				sessionLength: false,
				memberDelete: false,
				addMember: false,
				planSelect: false,
				changeCard: false,
				cancelStripe: false
			},
			progress: {
				cancelStripe: false,
				switchPeriod: false,
				organizationProfile: false,
				passwordRequirements: false,
				deviceApprovals: false,
				memberDelete: false,
				sessionLength: false,
				addMember: false,
				planSelect: false,
				changeCard: false
			},
			newMemberName: "",
			newMemberEmail: "",
			logoFileData: null,
			logoBucket: null,
			redirectReason: "",
			emailChangedFlag: false,
			emailSaving: false,
			replyToSaving: false,
			replyToEmail: "",
			buttonSaving: {
				updateLogo: false,
				updateCompanyName: false,
				updateReplyTo: false,
				updateEnforceTerms: false
			},
			rules: {
				required: value => !!value || 'Required.',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || 'Invalid e-mail.'
				},
			},
			customEmailBody: "This file sharing portal will allow you to quickly and securely exchange files with {{orgName}}. Not signed up? Don't worry, creating an account is easy. Simply click the link above, choose a password and that's it!",
			customEmailHeading: '{{userName}} from {{orgName}} has a file sharing portal for you to access. Click below to accept your invitation and create an account.',
			customEmailSubject: "{{orgName}} has invited you to a secure file portal - Accept before {{expirationDate}}"
		}
	},
	components: {
		SettingsItem,
		StripePayment,
		PlanSelection
	},
	computed: {
		promoDescription() {
			if (this.promoCodeDetails) {
				if (this.promoCodeDetails.coupon.name) {
					return this.promoCodeDetails.coupon.name;
				}
			}
		},

		planDescription() {
			let lowerCase = this.organization.tier.toLowerCase();
			let tierDescription = '';
			switch (lowerCase) {
				case "starter":
					tierDescription = "Basic Plan, Limited to 1 User, Maximum of 50 client portals, 500GB Storage";
					break;
				case "professional":
					tierDescription = "Professional Plan, Unlimited Users, Maximum of 1,000 client portals, 1TB Storage";
					break;
				case "large firm":
				case "advanced":
					tierDescription = "Large Business Plan, Unlimited Users, Maximum of 10,000 client portals, 10TB Storage";
					break;
				case "enterprise":
					tierDescription = "Corporate Plan, Unlimited Users, Unlimited Client Portals, Unlimited Data";
					break;
			}
			return tierDescription;
		},
		planPriceEstimate() {
			let planTier = this.planTitle(this.organization.tier);
			let planDetails = this.subscriptionPlanOptions.find(plan => plan.title === this.organization.tier);
			let planPrice = planDetails.price[this.stripeInterval.toLowerCase() === "month" ? "monthly" : "yearly"];
			if (this.stripeInterval.toLowerCase() !== "month") {
				planPrice = "$" + (Number(planPrice.replace('$', '')) * 12);
			}
			return `You have elected the ${planTier} plan to be billed at ${planPrice}.00 on a ${this.stripeInterval}ly basis.`;
		}
	},
	methods: {
		orgLoginURL(embedded) {
			return `${awsmobile.hosting_url}/signin?org=${this.organization && this.organization.id ? this.organization.id : ""}&embedded=${embedded}`;
		},
		planTitle(planId) {
			let planName = "Team";
			if (typeof planId === "string") {
				switch (planId.toLowerCase()) {
					case "starter":
						planName = "Starter";
						break;
					case "professional":
						planName = "Professional"
						break;
					case "large firm":
					case "advanced":
						planName = "Large Firm";
						break;
					case "enterprise":
						planName = "Enterprise";
						break;
					case "canceled":
						planName = "Cancelled";
						break;
				}
			} else {
				planName = "";
			}

			return planName;
		},
		renderEmailText(value, splitOnNewline) {
			let self = this;
			let updatedValue = value;
			let today = new Date();
			today.setDate(today.getDate() + 30);
			let terms = [
				{ token: "orgName", value: self.organization.companyName },
				{ token: "userName", value: self.user.displayName },
				{ token: "portalName", value: "John Doe" },
				{ token: "expirationDate", value: today.toLocaleDateString()}
			];

			for (let i = 0; i < terms.length; i++) {
				let term = terms[i];
				updatedValue = updatedValue.replaceAll("{{" + term.token + "}}", term.value);
				updatedValue = updatedValue.replaceAll("{{ " + term.token + " }}", term.value);
			}

			if (splitOnNewline) {
				return updatedValue.split('\n');
			} else {
				return updatedValue;
			}
		},
		usd(value) {
			return usdFormatter.format(value);
		},
		momentDate(timestamp) {
			return moment(timestamp * 1000).format("MM/DD/YYYY");
		},
		copyText(){
			document.getElementById("brandedLink").select();
			document.execCommand('copy');
		},
		saveCompanyName() {
			this.buttonSaving.updateCompanyName = true;
			orgService.mutation("updateCompanyName", {
				"company_name": this.organization["companyName"]
			}).then(response => {
				this.modals.organizationProfile = false;
				this.buttonSaving.updateCompanyName = false;
			}).catch(error => {
				console.error("Unable to update company name.");
				this.buttonSaving.updateCompanyName = false;
			});
		},
		updateEnforceTerms() {
			this.buttonSaving.updateEnforceTerms = true;
			//console.log("Updated ORG: " + JSON.stringify(this.organization));
			orgService.mutation("updateOrganizationBoolean", {
				"field": "termsEnforced",
				"value": this.organization["termsEnforced"],
				"terms": this.organization["termsAndConditions"]
			}).then(response => {
				this.buttonSaving.updateEnforceTerms = false;
			}).catch(error => {
				console.error("Unable to update organization preference.");
				this.buttonSaving.updateEnforceTerms = false;
			});
		},
		customEmailParamInsert(value){
			this.customEmailBody = this.customEmailBody + value;
		},
		updateEmail(replyToEmail){
			let self = this;
			if(replyToEmail){
				self.buttonSaving.updateReplyTo = true;
				orgService.mutation("updateGuestEmail",{subject:self.customEmailSubject, header: self.customEmailHeading, body: self.customEmailBody, replyToEmail : replyToEmail}).then(result =>{
					self.buttonSaving.updateReplyTo = false;
					try {
						woopra.track("Updated ReplyTo Email",{newReplyTo : replyToEmail});
					} catch (woopraError) {
						console.error("Woopra not loaded.");
					}
				}).catch(err =>{
					console.error("Failed to update Email params: " + err);
					self.emailSaving = false;
				})
			}else{
				this.emailSaving = true;
				orgService.mutation("updateGuestEmail",{subject:self.customEmailSubject, header: self.customEmailHeading, body: self.customEmailBody}).then(result =>{
					self.emailChangedFlag = false;
					self.emailSaving = false;
					try {
						woopra.track("Updated Email Template");
					} catch (woopraError) {
						console.error("Woopra not loaded.");
					}
				}).catch(err =>{
					console.error("Failed to update Email params: " + err);
					self.emailSaving = false;
				})
			}
		},
		removeCompanyLogo() {
			this.buttonSaving.updateLogo = true;
			orgService.mutation("uploadLogo", {
				"filename": "remove.del"
			}).then(response => {
				this.organization.logo_s3_path = null;
				setTimeout(() => {
					EventBus.$emit("refreshlazy", {oldId: this.organization.id, newId: "none"});
					this.buttonSaving.updateLogo = false;
				}, 100);

				this.modals.organizationProfile = false;
			}).catch(error => {
				console.error("Unable to remove logo.");
				this.buttonSaving.updateLogo = false;
			});
		},
		saveCompanyLogo(file) {
			let self = this;
			self.buttonSaving.updateLogo = true;
			if (file && file.target && file.target.files.length > 0 && file.target.files[0]) {
				let uploadTargetFile = file.target.files[0];
				let fileName = uploadTargetFile.name;
				if ((uploadTargetFile.size / 1024) > 1000) {
					self.buttonSaving.updateLogo = false;
					alert("Please select a logo smaller than 1mb.")
				} else {
					toBase64(file.target.files[0]).then(logoFileData => {
						//1. upload org logo to s3
						//2. save s3 id to self.organization.logo_s3_path and company name
						//3. update org in dynamo
						orgService.mutation("uploadLogo", {
							"filename": fileName,
							"data": logoFileData
						}).then(response => {
							try {
								woopra.track("Uploaded Company Logo");
							} catch (woopraError) {
								console.error("Woopra not loaded.");
							}
							self.organization.logo_s3_path = response;
							setTimeout(() => {
								EventBus.$emit("refreshlazy", {oldId: self.organization.logo_s3_path, newId: self.organization.logo_s3_path});
								self.buttonSaving.updateLogo = false;
							}, 500);
						}).catch(error => {
							console.error("Unable to upload logo.");
							self.notification("error", "Unable to load logo. Please make sure the file is a picture format and less than 100kb.");
							self.buttonSaving.updateLogo = false;
						});
					}).catch(fileDataError => {
						console.error("File data error: " + JSON.stringify(fileDataError));
						self.buttonSaving.updateLogo = false;
					});
				}
			} else {
				console.error("No file selected.");
				self.buttonSaving.updateLogo = false;
			}
		},
		getOrg() {
			let self = this;
			return new Promise(resolve => {
				stripeService.query("getOrgById", {org_id: self.user.administratorOf}, "organization", {vuexCache: false}).then(refreshOrg => {
					//console.log("ORG: " + JSON.stringify(this.organization));
					if (!this.organization["termsAndConditions"]) {
						this.organization["termsAndConditions"] = "Responsibility for Data: You have sole responsibility for all Files you upload into this portal through use of the Services. You acknowledge and agree that the Portal Host will not be responsible for any failure of the Services to store a Guest File, for the deletion of a Guest File stored on the Services, or for the corruption of or loss or any data, information, or content contained in a Guest File. You acknowledge sole responsibility for and assume all risk arising from your Portal access.";
					}
					resolve(refreshOrg);
				});
			});
		},
		editBillingInformation() {
			stripeService.jumpToEditBillingPage(this.organization.stripeCustomerId);
		},
		getStripe(billingEdit) {
			let self = this;
			//console.log(JSON.stringify(self.organization));
			if (!billingEdit) {
				let billingEdit = false;
			}
			return new Promise((resolve, reject) => {
				if (self.organization && self.organization.stripeCustomerId) {
					stripeService.query('getStripe', {
						stripeCustomerId: self.organization.stripeCustomerId,
						stripeEdit: billingEdit
					})
						.then(response => {
							if (response) {
								response = JSON.parse(response);
								self.stripeStatus = response.stripeStatus;
								self.stripeSubId = response.subId;
								self.stripeNextPayment = response.nextBillingDate;
								self.stripeStartDate = response.startDate;
								self.invoices = response.inv;
								self.stripeInterval = response.payInterval;
								self.stripeBillingAmount = response.amount;
								self.stripeLastFour = response.last4;
								self.stripeTrialEndDate = response.trialEndDate;
								self.stripeCardBrand = response.cardBrand;
								self.stripeCardExpiration = response.cardExpiration;

								stripeService.mutation("updateStripe",
									{
										orgId : self.organization.id,
										subscriptionId: self.stripeSubId,
										newTier: self.organization.tier, //orgTier
										cancelStripe: false,
										calcProration: true,
										stripeCustomerId: self.organization.stripeCustomerId,
										stripeDiscount: self.stripeDiscount //isAnnual
									}).then(stripePlanUpdateResponse => {
									self.stripeNextInvoiceAmount = stripePlanUpdateResponse;
								}).catch(err => {
									orgService._debug(err);
								});

								stripeService.query("getOrgById", {org_id: self.user.administratorOf}, "organization", {vuexCache: false}).then(refreshOrg => {
									self.newOrganizationTier = refreshOrg.tier;
									if (self.$refs.planSelector) {
										self.$refs.planSelector.refresh();
									}

									resolve("success");
								});
							} else {
								reject("Unable to get stripe response.");
							}
						}).catch(error => {
							reject(error);
					});
				}
			})
		},
		cancelStripe(){
			let self = this;
			if(self.organization && self.organization.stripeCustomerId){
				stripeService.mutation("updateStripe",
					{
						orgId : self.organization.id,
						subscriptionId: self.stripeSubId,
						newTier: 'canceled',
						cancelStripe: true,
						calcProration: false,
						stripeCustomerId: self.organization.stripeCustomerId,
						stripeDiscount: false
					}).then(stripeCancelled => {
						stripeService._log("Subscription has been cancelled, refreshing organization.");
						stripeService.mutation("sendFeedback", {
							message: self.cancelComments,
							feedbackType: self.cancelSelection
						}).then(feedbackResult => {
							self.getOrg().then(result => {
								self.progress.cancelStripe = false;
								self.modals.cancelStripe = false;
								self.newOrganizationTier = "cancelled";
								if (self.$refs.planSelector) {
									self.$refs.planSelector.refresh();
								}
							});
						});
				}).catch(err => {
					orgService._debug("Settings - Subscription Cancel Error - " + err);
					self.progress.cancelStripe = false;
					self.modals.cancelStripe = false;
				});
			}
		},
		planProrationMessage({ plan, discount, stripePlanUpdateResponse }) {
			let message = "";
			if (stripePlanUpdateResponse) {
				if (stripePlanUpdateResponse.indexOf("-") > -1) {
					message = `Selecting the ${discount ? "Annual" : "Monthly"} ${plan} Plan will issue a prorated refund of ${stripePlanUpdateResponse} please click 'Update' to confirm this change.`;
				} else {
					message = `Selecting the ${discount ? "Annual" : "Monthly"} ${plan} Plan will charge a prorated invoice of ${stripePlanUpdateResponse} please click 'Update' to confirm this change.`;
				}
			}

			return message;
		},
		saveChangeStripePlan({ plan, discount, stripePlanUpdateResponse }) {
			return new Promise(resolve => {
				this.changeStripePlan(plan, discount);
				this.closePopup("confirmPlanChange");
				resolve(true);
			});
		},
		cancelChangeStripePlan() {
			return new Promise(resolve => {
				this.$refs.planSelector.setDiscount(this.stripeInterval.toLowerCase() !== "month");
				this.$refs.planSelector.refresh();
				this.$refs.planSelector.setInProgress(false);
				this.closePopup("confirmPlanChange");
				resolve(true);
			});
		},
		promptChangeStripePlan({plan, discount, onlyName}) {
			let self = this;
			let currentPlanIsDiscounted = self.stripeInterval.toLowerCase() !== "month";

			if (plan && self.organization && !self.stripeSubId && plan !== "none") {
				//No current subscription
				self.$refs.planSelector.setInProgress(true);
				stripeService.jumpToNewAccountCheckoutPage(
					plan.toLowerCase(),
					discount,
					self.organization.stripeCustomerId).then(response => {
						console.log("Redirecting user to stripe checkout page.");
					self.$refs.planSelector.setInProgress(false);
					});
			} else if (plan && self.organization && self.organization.stripeCustomerId && (plan.toLowerCase() !== self.organization.tier || discount !== currentPlanIsDiscounted) && plan !== "none") {
				self.$refs.planSelector.setInProgress(true);
				stripeService.mutation("updateStripe",
					{
						orgId : self.organization.id,
						subscriptionId: self.stripeSubId,
						newTier: plan.toLowerCase(), //orgTier
						cancelStripe: false,
						calcProration: true,
						stripeCustomerId: self.organization.stripeCustomerId,
						stripeDiscount: discount //isAnnual
					}).then(stripePlanUpdateResponse => {
					stripeService._log("Subscription proration has been estimated.");

					this.openPopup('confirmPlanChange', { plan, discount, stripePlanUpdateResponse });
				}).catch(err => {
					orgService._debug("Settings - Subscription Plan Proration Error - " + err);
				});
			}
		},
		changeStripePlan(planName, discount) {
			let self = this;
			if(self.organization && self.organization.stripeCustomerId){
				stripeService.mutation("updateStripe",
					{
						orgId : self.organization.id,
						subscriptionId: self.stripeSubId,
						newTier: planName.toLowerCase(), //orgTier
						cancelStripe: false,
						calcProration: false,
						stripeCustomerId: self.organization.stripeCustomerId,
						stripeDiscount: discount //isAnnual
					}).then(stripePlanUpdateResponse => {
					stripeService._log("Subscription plan has been updated, refreshing organization.");
					self.getOrg().then(result => {
						self.getStripe(false);
						//done
						self.stripeInterval = discount ? 'year' : 'month';
						vaultService.mutation("updateVaultForTier",{}).then(response =>{
							for (let i = 0; i < self.vaults.length; i++) {
								if (self.vaults[i].org_id === self.organization.id) {
									self.vaults[i].orgTier = self.organization.tier;
								}
							}
						}).catch(e =>{
							vaultService._log("Updating vaults to new tier failed : " + JSON.stringify(e));
						});

						self.$refs.planSelector.setInProgress(false);
					});
				}).catch(err => {
					orgService._debug("Settings - Subscription Plan Update Error - " + err);
					self.$refs.planSelector.setInProgress(false);
				});
			}
		},
		updateMemberPermissions(email, valueToChange) {
			let self = this;
			orgService.mutation("updatePerms", {memberEmail: email, valueToChange: valueToChange}).then(result => {
				self.getOrg().then(refreshOrg => {
				});
			});
			try {
				woopra.track("Change Member Permission", {Company : self.organizationId.companyName, PermissionChanged: valueToChange});
			} catch (woopraError) {
				console.error("Woopra not loaded.");
			}
		},
		init(addedMember) {
			let self = this;
			let billingEdit = false;
			if (this.$route.query.updated === 'true') {
				billingEdit = true;
			}

			this.redirectReason = this.$route.query.reason;

			if (self.organization && self.organization.id) {
				if (self.organization.tier !== 'null') {
					console.log("Organization loaded with tier: " + self.organization.tier);
					self.newOrganizationTier = self.organization.tier;
				}

				orgService.query("getOrgById", {org_id: self.user.administratorOf}, "organization", {vuexCache: false}).then(refreshOrg => { //To refresh after adding a new member.
					if (self.organization.body) {
						self.customEmailBody = self.organization.body;
						self.customEmailHeading = self.organization.header;
						self.customEmailSubject = self.organization.subject;
					}

					if (self.organization.replyToEmail) {
						self.replyToEmail = self.organization.replyToEmail;
					}

					orgService.query("getMembersForOrgId", {org_id: self.organization.id}, "members", {vuexCache: false}).then(members => {
						self.perms = [];
						if (members && self.organization.permissions) {
							for (let x = 0; members.length > x; x++) {
								if(members[x]._deleted !== 1){
									for (let p = 0; self.organization.permissions.length > p; p++) {
										if (members[x].email === self.organization.permissions[p].user_email) {
											self.perms.push({
												...members[x],
												...self.organization.permissions[p]
											});
											break;
										}
									}
								}
							}
						}

						self.loading = false;
					}).catch(err => {
						orgService._debug("Settings - getMembersForOrgId Error - " + err);
						self.loading = false;
					});
				});

				if (self.organization && self.organization.stripeCustomerId) {
					let tempOrgPlan = self.organization.tier;
					self.getStripe(billingEdit).then(result => {
						self.getOrg().then(result => {
							if(tempOrgPlan !== self.organization.tier){
								vaultService.mutation("updateVaultForTier",{}).then(response =>{
									for (let i = 0; i < self.vaults.length; i++) {
										if (self.vaults[i].org_id === self.organization.id) {
											self.vaults[i].orgTier = self.organization.tier;
										}
									}
								}).catch(e =>{
									vaultService._log("Updating vaults to new tier failed : " + JSON.stringify(e));
								});
							}
							stripeService._log("Returning from Stripe Edit, refreshing organization.");
						});
					}).catch(err => {
						self.getOrg().then(result => {
							stripeService._log("Returning from Stripe Edit, refreshing organization.");
						});
					});
				} else {
					//TODO: No stripe ID. Redirect to /new or jump to checkout?
					console.error("UNKNOWN ERROR, NO STRIPE CUSTOMER ID.");
				}
			} else {
				console.error("UNABLE TO FIND ORG ID."); //SOMEDAY: Redirect to createNewAccount flow?
			}
		},
		addMember() {
			let self = this;
			self.progress.addMember = true;
			if(self.newMemberEmail && self.newMemberName){
				orgService.mutation("inviteNewMember", {
					org_id: self.organization.id,
					email: self.newMemberEmail.toLowerCase().trim(),
					name: self.newMemberName.trim()
				}).then(response => {
					try {
						woopra.track("Create New Member", {MemberEmail: self.newMemberEmail, Company : self.organizationId.companyName});
					} catch (woopraError) {
						console.error("Woopra not loaded.");
					}
					//console.log("Member created.");
					self.modals.addMember = false;
					self.progress.addMember = false;
					self.newMemberEmail = '';
					self.newMemberName = '';
					self.loading = true;
					setTimeout(() => {
						self.init(true);
					}, 500);
				}).catch(e =>{
					self.addMemberError = e.graphQLErrors[0].message;
					self.addMemberError = self.addMemberError.replace("undefined - ", "");
					self.addMemberAlert = true;
				});
			}else{
				self.notification("error","Both Name and Email are required, please try again.");
				self.progress.addMember = false;
			}
		},
		disableMember() {
			let self = this;
			orgService.mutation("disableMember", {memberId: self.memberToDelete}).then(response => {
				if (response) {
					orgService.query("getMembersForOrgId", {org_id: self.organization.id}, "members", {vuexCache: false}).then(members => {
						self.perms = [];
						if (members && self.organization.permissions) {
							for (let x = 0; members.length > x; x++) {
								if (members[x]._deleted !== 1) {
									for (let p = 0; self.organization.permissions.length > p; p++) {
										if (members[x].email === self.organization.permissions[p].user_email) {
											self.perms.push({
												...members[x],
												...self.organization.permissions[p]
											});
											break;
										}
									}
								}
							}
						}
						self.loading = false;
					});
					self.progress.memberDelete = false;
					self.modals.memberDelete = false;
					self.memberToDelete = "";

				} else {
					self.progress.memberDelete = false;
					self.modals.memberDelete = false;
					self.memberToDelete = "";
				}
			})
		}
	},
	created() {
		this.logoBucket = awsmobile.aws_org_logos_s3_bucket;
	},
	mounted() {
		try {
			Tawk_API.showWidget();
		} catch (tawkError) {
			console.error("Unable to load chat.");
		}

		if (this.$route.query.updated === 'true' || this.$route.query.register === 'true') {
			this.tabs = 1;
		}

		if (this.$route.query.step) {
			try {
				this.tabs = Number(this.$route.query.step);
			} catch (error) {
				console.error("Unable to interpret requested step.");
			}
		}

		if (!this.newOrganizationTier || this.newOrganizationTier !== "null") {
			this.newOrganizationTier = "Professional";
		}
		this.init();
		this.logoBucket = awsmobile.aws_org_logos_s3_bucket;
	}
});


const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsArrayBuffer(file);
	reader.onload = () => resolve(btoa(String.fromCharCode.apply(null, new Uint8Array(reader.result))));
	reader.onerror = error => reject(error);
});
