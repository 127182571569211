<template>
    <div class="fill">
        <v-btn @click="stripeCheckout"
               class='btn mt-4'
               color="primary">{{ buttonText ? buttonText : 'Stripe Checkout Test' }}
        </v-btn>

    </div>
</template>

<script>
import {VueController} from '@/service/support/vuex-controller'
import stripeService from '@/service/stripe-service';

export default VueController('StripePayment', {
    props: {
        buttonText: {
            type: String
        },
        updateStripeCard: {
            type: Boolean
        }
    },
    data() {
        return {
            complete: false
        }
    },
    state: [
        "newOrganizationTier",
        "newOrganizationName",
        "stripeDiscount",
        "organization",
        "validPromoCode",
        "user"
    ],
    methods:
        {
            stripeCheckout() {
                stripeService.jumpToNewAccountCheckoutPage(this.newOrganizationTier, this.stripeDiscount, this.organization.stripeCustomerId).then(error =>{
                    if (error) {
                        //TODO: Show option to reprocess?
                    }
                });
            }
        }
});
</script>

<style lang="scss" scoped>

</style>
