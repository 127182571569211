<template>
	<div class="container settings-item">
		<div>
			<div col lg="9" sm="10" cols="9">
				<h4>
					<slot name="title"></slot>
				</h4>
				<p>
					<slot name="description"></slot>
				</p>
			</div>
			<div col lg="3" sm="2" cols="3" class="text-center ma-auto">
				<slot name="action"></slot>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'settings-item'
	}
</script>
<style lang="scss">
	.settings-item {
		border-bottom: 1px solid #efefef;
		padding-bottom: 16px;
		padding-top: 16px;
	}
</style>
